<template>
  <div class="profile-page">
    
    <el-row :gutter="30">
       <el-col :lg="1">
      <el-button
          size="mini"
          icon="el-icon-back"
          class="default-button  btn button-left"
          @click="goBack()"
          >Back
          
          </el-button
        >
       </el-col>
      <el-col :lg="{ span: 6, offset: 2 }" :md="8" :sm="24">
        <div
          class="profile-card text-center"
          shadow="never"
          style="max-width: 400px; margin-bottom: 20px"
        >
          <div align="center" class="my-3">
            <img
              v-if="getUserprofilepicture && getUserprofilepicture.length > 20"
              :src="getUserprofilepicture"
              style="border-radius: 50%"
              alt="Avatar"
              width="50"
              class="inline-block"
            />
            <img
              v-else
              src="@/assets/img/avatar.svg"
              alt="Avatar"
              width="50"
              class="inline-block"
            />
          </div>
          <div class="list-group text-left">
            <router-link
              :class="getClass('statistics')"
              :to="{
                name: 'statistics',
              }"
              ><i class="el-icon-pie-chart"></i> Dashboard
              Statistics</router-link
            >
            <router-link
              :class="getClass('expiration-reminders')"
              :to="{
                name: 'expiration-reminders',
              }"
              ><i class="el-icon-timer"></i> Expiration &amp;
              Reminders</router-link
            >

            <router-link
              :class="getClass('application-settings')"
              :to="{
                name: 'application-settings',
              }"
              ><i class="el-icon-setting"></i>Application Settings</router-link
            >

            <router-link
              :class="getClass('document-notifications')"
              :to="{
                name: 'document-notifications',
              }"
              ><i class="el-icon-bell"></i> Notifications</router-link
            >
           
            <router-link v-if="isWorkspaceIsBusiness(this.getActiveWorkspace)"
              :class="getClass('myapps-credentials')"
              :to="{
                name: 'myapps-credentials',
              }"
              
              ><i class="el-icon-link"></i> My Apps & Credentials</router-link
            >
            <router-link
            v-if="isWorkspaceIsBusiness(this.getActiveWorkspace)"
              :class="getClass('global-filters')"
              :to="{
                name: 'global-filters',
              }"
              
              ><i class="el-icon-s-operation"></i> Global Filters</router-link
            >
          </div>
        </div>
      </el-col>
      <el-col :lg="14" :md="16" :sm="24">
        <component :is="layout">
          <router-view :layout.sync="layout" />
        </component>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
import permissionsHelper from "@/mixins/permissionsHelper";
export default {
  name: "ProfileBodySettings",
  data() {
    return {
      layout: "div",
    };
  },
  mixins: [DocumentsHelper,permissionsHelper],
  mounted(){
    this.getCompanyInformation();
  },
  computed: {
    ...mapGetters("auth", [
      "getUpdateProfilePicStatus",
      "getAuthenticatedUser",
      "getupdateprofilepicture",
      "getActiveWorkspace"
    ]),
    getUserprofilepicture() {
      if (this.getupdateprofilepicture) {
        return this.getupdateprofilepicture;
      } else {
        return null;
      }
    },
    getClass() {
      return (routeName) => {
        if (routeName == this.$route.name) {
          return "list-group-item list-group-item-action active";
        } else return "list-group-item list-group-item-action";
      };
    },
  },
methods:{
  goBack(){
    this.$router.push({
      path:"/"
    })
  }
}
};
</script>
<style lang="scss" scoped>
.profile-page {
  .profile-card {
    border-radius: 4px;
    border: 1px solid #ebeef5;
    margin: 0 -1px;
    .list-group {
      .list-group-item {
        border-radius: 0;
        padding: 1em;
        border: none;
        color: #9299b8;
        [class^="el-icon"] {
          margin-right: 10px;
        }
        &.active {
          color: #f754a2;
          border-left: 2px solid #f754a2;
        }
      }
    }
  }
}
</style>